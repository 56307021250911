import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { ButtonBasic } from "../components/buttons"


const TitleBar = styled.div`
  width: 100%;
  background: #ABA17B;
  padding: 5px 5%;
  h2 {
    color: white;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 200;
    font-size: 38px;
    @media all and (max-width: 680px) {
      font-size: 28px;
    }
  }
  
`

const EnquireContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 3rem;
  
    p {
      color: #696969;
      font-weight: 500;
      line-height: 1em;
      font-size: 18px;
      a {
        text-decoration: none;
        color: #919191;
      }
    }
    .highlighted {
      color: #3c763d;
      background-color: #dff0d8;
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
    }
    form {
      input {
        border-radius: 0;
        border: 1px solid #ccc;
        padding: 10px 15px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      input[type=radio] {
        display: inline-block;
      }
      .radiolabel {
        display: inline-block;
        margin: 0 0 0 1em;
        line-height: 1;
        fontweight: 200;
        font-size: 14px;      
        color: #909090;
      }
      .checkboxlabel {
        display: inline-block;
        margin: 0 0 0 1em;
        line-height: 1;
        fontweight: 200;
      }
      label {
        color: #666;
        line-height: 2;
        text-align: left;
        display: block;
        margin: 10px 0 5px;
        font-weight: 200;
        font-family: "Playfair Display", Georgia, "Times New Roman", serif;
        span {
          color: #ee0000;
        }
      }
      textarea {
        width: 100%;
        height: 75px;
        border-radius: 0;
        border: 1px solid #ccc;
      }
      p {
        color: #bf1650;
      }

      p::before {
        display: inline;
        content: "⚠ ";
      }
      button {
        margin-top: 2rem;
      }
      .error-label {
        color: #a94442;
      }
      .error-input {
        border-color: #a94442;
        background-color: #f2dede;
      }
    }
  
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 680px) {
   flex-wrap: wrap;
  }
  
`
const Column = styled.div`
  width: 50%;
  padding: 30px 50px 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media all and (max-width: 680px) {
    width: 100%;
  }
  
`

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const EnquireForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = (data: any) => {
    setSubmitted(true)
    fetch("https://getform.io/f/753dde84-8d68-4a7d-810f-4491912acffb", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "enquire", ...data }),
    })
      .then(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmission",
          ...data,
        })
      })
      .catch((error) => alert(error))
  }
  return (
      <div>
        <TitleBar>
          <h2>ENQUIRE NOW</h2>
        </TitleBar>
        <EnquireContainer>
          <div className="enquire">
            {submitted ? (
              <p className="highlighted">
                Thanks for your enquiry, we will be in touch with you shortly.
              </p>
            ) : (
              <form name="enquire-form" onSubmit={handleSubmit(onSubmit)}>

                <input type="hidden" name="form-name" value="contact" />
                <ColumnWrapper>
                  <Column>

                  {/* Name Field (Required) */}

                      <label htmlFor="name" className={errors.name && "error-label"}>
                      Your Name: <span>*</span>
                      </label>
                      <input
                      className={errors.name && "error-input"}
                      type="text"
                      id="name"
                      name="name"
                      placeholder="(First & Last Name)"
                      ref={register({ required: true })}
                      />
                      {errors.name && <p>Your input is required</p>}

                  {/* Email Field (Required) */}

                      <label
                      className={errors.email && "error-label"}
                      htmlFor="email"
                      >
                      Email: <span>*</span>
                      </label>
                      <input
                      className={errors.email && "error-input"}
                      type="email"
                      name="email"
                      id="email"
                      ref={register({
                          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                          required: true,
                      })}
                      />
                      {errors.email && <p>Your input is required</p>}

                  {/* Nationality Field */}

                      <label htmlFor="nationality">Nationality:</label>
                      <input type="text" id="nationality" name="nationality" ref={register} />

                  {/* Dates Field (Required) */}

                      <label
                      className={errors.email && "error-label"}
                      htmlFor="email"
                      >
                      Dates you wish to visit: <span>*</span>
                      </label>
                      <input
                      className={errors.date && "error-input"}
                      type="date"
                      name="date"
                      id="date"
                      ref={register({
                          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                          required: true,
                      })}
                      />
                      {errors.date && <p>Your input is required</p>}

                  </Column>
                  <Column>

                  {/* No. Guests Field (Required)*/}

                      <label
                      className={errors.guests && "error-label"}
                      htmlFor="email"
                      >
                      Number of Guests travelling: <span>*</span>
                      </label>
                      <input
                      className={errors.date && "error-input"}
                      type="number"
                      name="guests"
                      id="guests"
                      ref={register({
                          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                          required: true,
                      })}
                      />
                      {errors.guests && <p>Your input is required</p>}

                  {/* Telephone Field */}

                      <label htmlFor="number">Telephone:</label>
                      <input type="tel" id="telephone" name="telephone" ref={register} />

                  {/* Preferred Response Field */}

                      <label htmlFor="response">Preferred response from us:</label>
                      <div id="response">
                        <input type="radio" id="mailresponse" name="response" checked ref={register} /><label className="radiolabel" htmlFor="mailresponse">Email</label>
                        <br></br>
                        <input type="radio" id="callresponse" name="response" ref={register} /><label className="radiolabel" htmlFor="callresponse">Call</label>
                      </div>
                        

                  {/* Time-to-call Field */}

                      <label htmlFor="calltime">Preferred time to call:</label>
                      <input type="time" id="calltime" name="calltime" ref={register} />      

                  </Column>
                </ColumnWrapper>
                <div style={{padding:"0 50px"}}>     
                  
                  <label
                    className={errors.message && "error-label"}
                    htmlFor="message"
                    >
                    What are you dreaming of experiencing? <span>*</span>
                  </label>
                  <textarea
                  id="message"
                  className={errors.message && "error-input"}
                  name="message"
                  ref={register({ required: true })}
                  />
                  {errors.message && <p>Your input is required</p>}

                  <div style={{marginTop: "20px"}}>
                    <input type="checkbox" id="subscribe" name="subscribe" value="yes" checked ref={register} /><label className="checkboxlabel" htmlFor="subscribe">Subscribe to our Newsletter. Keep up to date with specials and more!</label>
                    <input type="hidden" name="subscribe" value="no" />
                  </div>

                  <ButtonBasic type="submit" style={{display:"block" , marginTop:"20px"}}>Submit Enquiry</ButtonBasic>
                </div>

                    

              </form>
            )}
            
          </div>
        </EnquireContainer>
      </div>
  )
}

export default EnquireForm