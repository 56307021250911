import React from "react"
import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import TextSectionBackground from "../components/TextSectionBackground"
import { ActionButton } from "../components/buttons"
import { ButtonBasic } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import { SectionHeader } from "../components/Typography"
import styled from "styled-components"
import SEO from "../components/SEO"

const RatesWrapper = styled.div`
  .reservations {
    text-decoration: none;
    color: #919191;
    word-break: break-word;
  }
  h3 {
    text-align: left;
    color: #666;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 700;
    font-size: 28px;
    margin-top: 2em;
    @media all and (max-width: 980px) {
      font-size: 22px;
    }
  }
  h4 {
    text-align: left;
    color: #666;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 700;
    font-size: 28px;
    margin-top: 2em;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
    @media all and (max-width: 980px) {
      font-size: 22px;
    }
  }
  h5 {
    text-align: left;
    color: #666;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 700;
    font-size: 24px;
    margin-top: 3rem;
    
    @media all and (max-width: 980px) {
      font-size: 20px;
    }
  }
  p {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    color: #666;
    font-weight: 500;
    line-height: 1.4em;
    font-size: 16px;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
    span {
      font-family: "Oleo Script", display;
      margin-left: 20px;
      font-size: 24px;
      font-weight: 700;
    }
  }
  
`

const ButtonWrapper = styled.div`
  text-align: left;
  margin: 3rem 0 2%;
  button {
    margin: 8px 10px;
  }

`

const RateInclude = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  div {
    text-align: left;
    width: 30%;
    min-width: 270px;
    margin: 0 20px;
  }
  ul {
    padding-left: 20px;
  }    
  p, li {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    color: #666;
    font-weight: 500;
    line-height: 1.4em;
    font-size: 24px;
    margin-bottom: 15px;
    
    @media all and (max-width: 980px) {
      font-size: 18px;
    }
  }

  @media all and (max-width: 980px) {
    justify-content: left;
  }
  

`

const Accommodation = () => {
  const data = useStaticQuery(graphql`
    query {
      images: contentfulGallery(name: { eq: "Rates" }) {
        images {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      rate: contentfulRate {
        until
        amount
      }
    }
  `)
  return (
    <PageLayout>
      <SEO 
      title="Mavela Lodge Rates"
      description="Please contact us directly at reservations@mavela.Co.Za for the best possible rate, or to book the lodge for exclusive use."
      />
      <TextSectionBackground pa="3rem 5%">
        <RatesWrapper>
          <SectionHeader>Mavela Lodge Rates</SectionHeader>
          <h3>
            Please contact us directly at <a className="reservations" href="mailto:reservations@mavela.co.za">reservations@mavela.co.za</a>
            <br />
            for the best possible rate, or to book the lodge for exclusive use.
          </h3>
          <ButtonWrapper>
            <ButtonBasic>
              <a href="RackRates.pdf" rel="noopener noreferrer" target="_blank">
                Download Rates
              </a>
            </ButtonBasic>
            <EnquireButton />
            <ActionButton>
              <a
                href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
                rel="noopener noreferrer"
                target="_blank"
              >
                Check Availability
              </a>
            </ActionButton>
            <ActionButton>
              <a
                href="/special-rates"
                rel="noopener noreferrer"
                target="_blank"
              >
                Specials
              </a>
            </ActionButton>
          </ButtonWrapper>
          <RateInclude>
            <div>
              <h4>Rates Include:</h4>
              <ul>
                <li>Accommodation.</li>
                <li>
                  All meals (Breakfast, Lunch and / or High Tea as well as
                  Dinner)
                </li>
                <li>
                  Safari Activities: Two Game Drives daily with a professional
                  guide
                </li>
                <li>
                  Walking safari as an alternative to a morning game drive
                </li>
                <li>
                  Coffee, Tea, Hot Chocolate, breakfast juice and filtered still
                  water available all day
                </li>
                <li>
                  All drinks: soft drinks, South African wine, spirits and
                  beers.
                </li>
                <li>
                  Snacks and refreshments on both morning and afternoon safaris
                </li>
                <li>Wi-Fi available in the main lodge area</li>
                <li>VAT inclusive</li>
              </ul>
            </div>
            <div>
              <h4>Rates Exclude:</h4>
              <ul>
                <li>
                  Manyoni guest conservation levy**, additional activities,
                  gratuities.
                </li>
                <li>
                  **The Manyoni guest conservation levy is currently R175 per
                  person per night and will be invoiced with the accommodation.
                </li>
              </ul>
            </div>
            <div>
              <h4>Returning Guests</h4>
              <p>
                A discount on our rack rate is offered to all returning guests,
                please do indicate this to us when you make an enquiry via
                reservations@mavela.co.za
              </p>
            </div>
          </RateInclude>

          <h4>Experience the Mavela Magic!</h4>
          <h5>Reservation Policy</h5>
          <p>
            Reservations may be made by email, through our online booking
            facilities or through an agent. A pro forma invoice will be sent out
            once guests have advised of their firm intention to make a
            reservation, their dates and number of guests. Reservations will be
            confirmed upon receipt of a 50%deposit within 72 hours after
            submission of the pro forma invoice. Banking details are reflected
            on this invoice. Final payment to be made 72 hours prior to arrival.
            Last minute bookings need full payment to confirm a reservation.
            Specials may apply for a 2 night consecutive stay. Rates are subject
            to change at the discretion of the Mavela management.
          </p>
          <h5>Cancellation Policy</h5>
          <p>
            No cancellation penalties will be levied should the guest change
            their travel dates. Changes must be made at least 5 days prior to
            the original travel dates. Less than 7 days prior to arrival: 100%
            cancellation. 7 to 30 days prior to arrival 50% cancellation. More
            than 30 days : no cancellation penalty.
          </p>
          <h5>Check-In & Check-Out</h5>
          <p>
            Mavela welcomes guests from 14:00hrs, however a late arrival after
            15:30hrs could result in guests missing the afternoon game drive. We
            sadly wish you farewell at 11:00am.
          </p>
          <h5>Dietary Requirements</h5>
          <p>
            Please let us now in advance of any dietary requirements.
          </p>
          <h5>Child Policy</h5>
          <p>
            Mavela regrets but we do not accommodation children under the age of
            5, unless exclusive use of the lodge is booked.
          </p>
        </RatesWrapper>
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Accommodation
